import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Alternative_IP_Scanner/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Angry IP Scanner",
  "path": "/Quick_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Use the Angry IP Scanner to discover your Camera on your local Network",
  "image": "./QI_SearchThumb_AlternativeIPScanner_Angry_eng.png",
  "social": "/images/Search/QI_SearchThumb_AlternativeIPScanner_Angry_eng.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Alternative_IP_Scanner_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Angry IP Scanner' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the Angry IP Scanner to discover your Camera on your local Network' image='/images/Search/QI_SearchThumb_AlternativeIPScanner_Angry_eng.png' twitter='/images/Search/QI_SearchThumb_AlternativeIPScanner_Angry_eng.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/' locationFR='/fr/Quick_Installation/Alternative_IP_Scanner/Angry_IP_Scanner/' crumbLabel="Angry IP Scanner" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alternative-ip-scanner",
        "aria-label": "alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alternative IP Scanner`}</h1>
    <h2 {...{
      "id": "angry-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#angry-ip-scanner",
        "aria-label": "angry ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Angry IP Scanner`}</h2>
    <h4 {...{
      "id": "how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-to-find-your-ip-camera-in-the-network-with-an-alternative-ip-scanner",
        "aria-label": "how to find your ip camera in the network with an alternative ip scanner permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to find your IP Camera in the network with an alternative IP Scanner?`}</h4>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Win/INSTAR_Camera_Tool_2.0.5.msi.zip"
      }}>{`IP Camera Tool (Windows)`}</a>{`, `}<a href="https://itunes.apple.com/de/app/instar-camera-tool/id584435076?mt=12#" target="_blank" rel="noopener noreferrer">{`IP Camera Tool (macOS)`}</a>{` or `}<a parentName="p" {...{
        "href": "http://instar.de/media/wysiwyg/Products/Software/Camera_Tool_Linux/INSTAR_Camera_Tool_LINUX.zip"
      }}>{`IP Camera Tool (LINUX)`}</a>{` is for sure the most comfortable way to find your IP camera inside your network. Instructions on how to set up your INSTAR IP camera with the Camera Tool can be found here.`}</p>
    <p>{`If, for some reason, the IP Camera Tool does not work on your computer, here are a few other options to discover the IP address. The camera will try to receive an IP address automatically (DHCP) during the first installation. Most routers will give you a log file of fresh DHCP requests, in which you should be able to find the camera´s IP address. You should reach the cameras login portal by typing this address into the web browser´s address bar.`}</p>
    <p>{`If you do not have access to your router or if your router does not have any log files, please refer to alternative IP scanners - an examples is:`}</p>
    <h3 {...{
      "id": "angry-ip-scanner-windowsmacosxlinux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#angry-ip-scanner-windowsmacosxlinux",
        "aria-label": "angry ip scanner windowsmacosxlinux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Angry IP Scanner (Windows/MacOSX/Linux)`}</h3>
    <p>{`You can find the newest Version `}<a href="http://angryip.org/w/Download" target="_blank" rel="noopener noreferrer">{`HERE For Windows/macOSX/LINUX`}</a>{` - install and start the application, type in the IP range and start the search.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a6bc2ae6ce27d8720972ba332ca7364/20e5d/IPScanner_Windows_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHWHQKLB//EABgQAAMBAQAAAAAAAAAAAAAAAAABAhIT/9oACAEBAAEFAuSOUjiTSNIdI//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAAMBAAAAAAAAAAAAAAAAAAABMSD/2gAIAQEABj8CIiLH/8QAGxABAQABBQAAAAAAAAAAAAAAAQBhITFRgcH/2gAIAQEAAT8hXOYJNTqI+Vkss13v/9oADAMBAAIAAwAAABAXD//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EKr/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgMBAQAAAAAAAAAAAAABACFBUWFxgf/aAAgBAQABPxBQL9BK8BhMBGDSIitPGXc8YRddM//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6bc2ae6ce27d8720972ba332ca7364/e4706/IPScanner_Windows_01.avif 230w", "/en/static/0a6bc2ae6ce27d8720972ba332ca7364/e46ac/IPScanner_Windows_01.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a6bc2ae6ce27d8720972ba332ca7364/a0b58/IPScanner_Windows_01.webp 230w", "/en/static/0a6bc2ae6ce27d8720972ba332ca7364/8626f/IPScanner_Windows_01.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a6bc2ae6ce27d8720972ba332ca7364/e83b4/IPScanner_Windows_01.jpg 230w", "/en/static/0a6bc2ae6ce27d8720972ba332ca7364/20e5d/IPScanner_Windows_01.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a6bc2ae6ce27d8720972ba332ca7364/20e5d/IPScanner_Windows_01.jpg",
              "alt": "Angry IP Scanner",
              "title": "Angry IP Scanner",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please fill out the field IP Range with the IP range that your router´s DHCP service is set to.`}</p>
    <p>{`If you do not know the IP range, then simply use the command line on Windows, type in `}<strong parentName="p">{`ipconfig`}</strong>{` and check your computers IPv4 address (see screenshot below). Use this IP address in Angry IP Scanner and just change the last octet to 1 for the start IP address and 254 for the last IP address to search the complete /24 subnet. Make sure your camera is disconnected from your network and then press Start to begin the scan.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/20e5d/IPScanner_Windows_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB5SWVN5Af/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIABAMxMv/aAAgBAQABBQIu0LNKxJxmHVfj/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEhMhARcf/aAAgBAQAGPwKzLEvc5fT/xAAbEAACAQUAAAAAAAAAAAAAAAAAASEQMUFR8P/aAAgBAQABPyHuQI5DQ2Ns6bZEn//aAAwDAQACAAMAAAAQNw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAEAAgEEAwAAAAAAAAAAAAABABEhMUFRYXGBsf/aAAgBAQABPxCy0lruwAKt9sFVGCjiiKgqj1CaJ5qYo5/Cf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/e4706/IPScanner_Windows_02.avif 230w", "/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/e46ac/IPScanner_Windows_02.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/a0b58/IPScanner_Windows_02.webp 230w", "/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/8626f/IPScanner_Windows_02.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/e83b4/IPScanner_Windows_02.jpg 230w", "/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/20e5d/IPScanner_Windows_02.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a82fd39b5e9fc449f1f3290c8916aaa7/20e5d/IPScanner_Windows_02.jpg",
              "alt": "ipconfig command",
              "title": "ipconfig command",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can use the ipconfig command to confirm the IP range of your network.`}</p>
    <p>{`The scanner will now display all active devices in your network (marked in blue as shown below). In the case below we have 5 active devices.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4df4d031eac599bc167280f298503454/20e5d/IPScanner_Windows_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB1SiOo//EABkQAAIDAQAAAAAAAAAAAAAAAAARARIhMv/aAAgBAQABBQJRURHFtef/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQEgMTL/2gAIAQEABj8CaOYMp//EABgQAQEBAQEAAAAAAAAAAAAAAAEAESEx/9oACAEBAAE/IRFAdPCxhwXCs94wEX//2gAMAwEAAgADAAAAEDPP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QSf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQEAAgMBAAAAAAAAAAAAAAERAKEhMUHh/9oACAEBAAE/EAgL1EMBi0enzOUXPYF9THsaxupUsUmMCctduf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4df4d031eac599bc167280f298503454/e4706/IPScanner_Windows_03.avif 230w", "/en/static/4df4d031eac599bc167280f298503454/e46ac/IPScanner_Windows_03.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4df4d031eac599bc167280f298503454/a0b58/IPScanner_Windows_03.webp 230w", "/en/static/4df4d031eac599bc167280f298503454/8626f/IPScanner_Windows_03.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4df4d031eac599bc167280f298503454/e83b4/IPScanner_Windows_03.jpg 230w", "/en/static/4df4d031eac599bc167280f298503454/20e5d/IPScanner_Windows_03.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4df4d031eac599bc167280f298503454/20e5d/IPScanner_Windows_03.jpg",
              "alt": "Angry IP Scanner",
              "title": "Angry IP Scanner",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The scan reveals that there are 5 active devices inside the given IP range.`}</p>
    <p>{`Now connect your camera to your router and re-run the scan. The scanner will display a new network device with the hostname `}<strong parentName="p">{`ipcamera`}</strong>{` (only VGA cameras). If the Angry IP scanner does not resolve the hostname, it under Tools - Fetchers. If you are using more than one camera in your network we recommend, you only connect one camera at a time in order to differentiate them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4830b9246a02678bfdd99631fb6ceef8/20e5d/IPScanner_Windows_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHUsMFkh//EABoQAAICAwAAAAAAAAAAAAAAAAECABIREyH/2gAIAQEAAQUCqpGtclRAeWEZhP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAAxASCh/9oACAEBAAY/AkLBRT//xAAaEAEBAQADAQAAAAAAAAAAAAABACERMcHR/9oACAEBAAE/IfoSwZhXwgNzzmyXu//aAAwDAQACAAMAAAAQkw//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhEUFRMWFx/9oACAEBAAE/EDNofgwLDj4mHzVxL+x5d+ojltpg1tXGf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4830b9246a02678bfdd99631fb6ceef8/e4706/IPScanner_Windows_04.avif 230w", "/en/static/4830b9246a02678bfdd99631fb6ceef8/e46ac/IPScanner_Windows_04.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4830b9246a02678bfdd99631fb6ceef8/a0b58/IPScanner_Windows_04.webp 230w", "/en/static/4830b9246a02678bfdd99631fb6ceef8/8626f/IPScanner_Windows_04.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4830b9246a02678bfdd99631fb6ceef8/e83b4/IPScanner_Windows_04.jpg 230w", "/en/static/4830b9246a02678bfdd99631fb6ceef8/20e5d/IPScanner_Windows_04.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4830b9246a02678bfdd99631fb6ceef8/20e5d/IPScanner_Windows_04.jpg",
              "alt": "Angry IP Scanner",
              "title": "Angry IP Scanner",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you are unsure which host is the camera, unplug the camera and re-scan the network. The now missing entry will reveal the cameras IP address.
Once you discovered your camera´s IP address, simply copy the address by right-clicking on it and paste it into your web browsers address bar and press the Return button.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a2ce7af64cb1f3f669e781c247984d13/20e5d/IPScanner_Windows_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdR52EAP/8QAFxABAQEBAAAAAAAAAAAAAAAAAQASMf/aAAgBAQABBQIBMkhHNkpf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAExEP/aAAgBAQAGPwIUIi7/AP/EABoQAAICAwAAAAAAAAAAAAAAAAAhEDERQWH/2gAIAQEAAT8hog/CBOiiliz0f//aAAwDAQACAAMAAAAQsA//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Qsa//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAwEAAwEAAAAAAAAAAAABACExEUFRYZH/2gAIAQEAAT8QTvGY/sNwPJwgkWw7kaF4FKCKN1W18i30Axn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2ce7af64cb1f3f669e781c247984d13/e4706/IPScanner_Windows_05.avif 230w", "/en/static/a2ce7af64cb1f3f669e781c247984d13/e46ac/IPScanner_Windows_05.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a2ce7af64cb1f3f669e781c247984d13/a0b58/IPScanner_Windows_05.webp 230w", "/en/static/a2ce7af64cb1f3f669e781c247984d13/8626f/IPScanner_Windows_05.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a2ce7af64cb1f3f669e781c247984d13/e83b4/IPScanner_Windows_05.jpg 230w", "/en/static/a2ce7af64cb1f3f669e781c247984d13/20e5d/IPScanner_Windows_05.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a2ce7af64cb1f3f669e781c247984d13/20e5d/IPScanner_Windows_05.jpg",
              "alt": "Angry IP Scanner",
              "title": "Angry IP Scanner",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Copy the IP address and paste it into your browsers address bar to load the cameras web user interface.`}</p>
    <p>{`You will now be greeted by the camera´s login page - the default credentials are username `}<strong parentName="p">{`admin`}</strong>{` and the password can be left `}<strong parentName="p">{`blank`}</strong>{` (or type in `}<strong parentName="p">{`instar`}</strong>{` in case of a HD camera model). Congratulations! You now entered the cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`Web User Interface`}</a>{`.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      